import { DocumentType, EspaceType } from '@innedit/innedit-type';
import { navigate } from 'gatsby';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import { ListItemProps } from '../../../containers/Admin/props';
import IconEye from '../../../icons/Eye';
import ListItem from './index';

const ListItemBoutique: FC<ListItemProps<EspaceType>> = ({
  docId,
  index,
  model,
  onClick,
}) => {
  const [doc, setDoc] = useState<DocumentType<EspaceType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  const handleChangeEspace = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    return navigate(`/espaces/${docId}/dashboard/`);
  };

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      actions={[
        {
          iconLeft: IconEye,
          onClick: handleChangeEspace,
        },
      ]}
      displayActionId
      doc={doc}
      index={index}
      onClick={onClick}
    >
      <strong className="name">{doc.name}</strong>
    </ListItem>
  );
};

export default ListItemBoutique;
